interface AccodionProps {
  element: HTMLElement;
}

class Accordion {
  element: HTMLElement;

  constructor(options: AccodionProps) {
    this.element = options.element;
    this.attachAccordion();
  }

  attachAccordion() {
    this.element.addEventListener('click', this.toggleAccordion.bind(this));
  }

  toggleAccordion(event: MouseEvent) {
    let target = event.target as HTMLElement;
    while (target !== this.element && !target.classList.contains('accordion')) {
      target = target.parentElement as HTMLElement;
    }
    if (target.classList.contains('accordion')) {
      target.classList.toggle('accordion--active');
      const panel = target.nextElementSibling as HTMLElement;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }
}

export default Accordion;